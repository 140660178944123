import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import {LanguageContext} from '../context/LanguageContext';
import '../styles/Navbar.css';
import logo from "../assets/logo_long.png";
import toggle from '../assets/icons/toggleMenu-Icon.svg';

const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [scrollTimeout, setScrollTimeout] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const {language, changeLanguage} = useContext(LanguageContext);
  const navbarLinksRef = useRef(null); // Ref to store navbar-links element

  const handleScroll = useCallback(() => {
    if (scrollTimeout) {
      clearTimeout(scrollTimeout);
    }

    setMenuOpen(false);

    if (window.scrollY > lastScrollY) {
      const timeout = setTimeout(() => {
        setShowNavbar(false);
      }, 10);
      setScrollTimeout(timeout);
    } else {
      setShowNavbar(true);
    }

    setLastScrollY(window.scrollY);
  }, [lastScrollY, scrollTimeout]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }
    };
  }, [handleScroll, scrollTimeout]);

  const toggleMenu = () => {
    if (menuOpen) {
      navbarLinksRef.current.classList.add('closing');

      setTimeout(() => {
        setMenuOpen(false);
        navbarLinksRef.current.classList.remove('closing');
      }, 300);
    } else {
      setMenuOpen(true);
    }
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <nav className={`navbar ${showNavbar ? 'navbar-show' : 'navbar-hide'}`}>
      <div className="navbar-container">
        <div className="navbar-logo">
          <Link to="/" onClick={closeMenu}>
            <img src={logo} alt="Logo" />
          </Link>
        </div>
        <div className="menu-icon" onClick={toggleMenu}>
          <img src={toggle} alt="toggleMenu" />
        </div>
        <div ref={navbarLinksRef} className={`navbar-links ${menuOpen ? 'active' : ''}`}>
          <Link to="/" onClick={closeMenu}>{language === 'en' ? 'Home' : 'Начало'}</Link>
          <a href={require("../assets/pdf/Viktor_Asenov_CV.pdf")} target='_blank' rel="noreferrer" onClick={closeMenu}>{language === 'en' ? 'CV' : 'Портфолио'}</a>
          <Link to="/about" onClick={closeMenu}>{language === 'en' ? 'About Me' : 'За Мен'}</Link>
          <Link to="/contact" onClick={closeMenu}>{language === 'en' ? 'Contact' : 'Контакт'}</Link>
          <Link to="/services-apps" onClick={closeMenu}>{language === 'en' ? 'Services & Apps' : 'Услуги и Приложения'}</Link>

          <div className="language-switcher">
            <button className={language === 'en' ? 'active' : ''} onClick={() => changeLanguage('en')}>EN</button>
            <button className={language === 'bg' ? 'active' : ''} onClick={() => changeLanguage('bg')}>BG</button>
          </div>

        </div>
      </div>
    </nav>
  );
};

export default Navbar;
